import { Injectable, OnDestroy, Predicate, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { City, Country, Hotel, HotelServiceEnum, HotelTypeEnum } from '@appTypes/api.types';
import { UnsafeValue } from '@appTypes/common.types';
import { DistrictsTreeItem } from '@appTypes/redefined-types';
import { greaterThan, lessThan, minDate, minDateThanField, minNumbers } from '@core/misc/validators.utils';
import { RequestStateModel } from '@core/store/store.models';
import { StoreService } from '@core/store/store.service';
import { SVGIcon } from '@data/svg-icons.data';
import { environment } from '@env/environment';
import { UpdateFormValue } from '@ngxs/form-plugin';
import {
  STATIC_OPTIONS_HOTEL_CLASSES_STARS,
  STATIC_OPTIONS_HOTEL_MEAL_PLANS,
  STATIC_OPTONS_HOTEL_SERVICES,
  STATIC_OPTONS_HOTEL_TYPES
} from '@pages/_common/static-data.const';
import { HotelsBookingInstructionsComponent } from '@pages/hotels-page/desktop/hotels-search-desktop-form/hotels-booking-instructions/hotels-booking-instructions.component';
import { NGXS_HOTELS_SEARCH_FORM } from '@pages/hotels-page/hotels-page.const';
import {
  hotelsGetDestinationCountries,
  hotelsGetHotels,
  hotelsGetLocations,
  hotelsResetResult,
  hotelsSearchHotels
} from '@pages/hotels-page/state/hotels.actions';
import { HotelsState } from '@pages/hotels-page/state/hotels.state';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { addDays, differenceInDays } from 'date-fns';
import { Observable, Subject, combineLatest, filter, map, take, takeUntil, tap } from 'rxjs';
import { appSetCurrency, appSetSearchByUrlModule, appSetSellCurrency } from 'src/app/state/app.actions';
import { AppPersistedState } from '../../../state/app.persisted.state';
import { AppState } from '../../../state/app.state';

@Injectable()
export class HotelsSearchFormService implements OnDestroy {
  public today = new Date(new Date().setHours(0, 0, 0, 0));

  public isSubmited = false;

  public readonly ngxsForm = NGXS_HOTELS_SEARCH_FORM;
  public formStatus$ = this.storeService.select(HotelsState.searchLoadStatus);
  public _destroySubscriptions: Subject<void> = new Subject<void>();
  public pages = [
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: -1, label: 'All' }
  ];
  public pageRowCount = signal<number>(25);

  public readonly formGroup = new FormGroup({
    checkInDate: new FormControl<Date>(this.today, [
      Validators.required,
      minDate(this.today, 'Check In Date must be after today')
    ]),
    checkOutDate: new FormControl<Date>(addDays(this.today, 1), [
      Validators.required,
      minDateThanField('checkInDate', '"Check out" Date must be after "Check in" date')
    ]),
    checkInAndCheckOutDaysCount: new FormControl<number | null>(1, [
      Validators.required,
      Validators.max(30),
      minNumbers(1, `Number of nights must be greater than {{minNumbers}}`)
    ]),
    children: new FormControl<number>(0),
    adults: new FormControl<number>(2),
    childrenAges: new FormArray<FormControl<number | null>>([]),
    hotelCodes: new FormControl<string[] | null>(null),
    locations: new FormControl<DistrictsTreeItem[] | null>(null),
    mealPlans: new FormControl<string[] | null>(null),
    countryCode: new FormControl<string | null>(
      this.storeService.selectSnapshot(AppPersistedState.activedDestinationCountry),
      [Validators.required]
    ),
    districtUIDs: new FormControl<string[] | null>(null), //?
    extendedSearch: new FormControl<boolean>(false),

    totalPriceFrom: new FormControl<number | null>(null, [lessThan('totalPriceTo')]),
    totalPriceTo: new FormControl<number | null>(null, [greaterThan('totalPriceFrom')]),
    hotelTypes: new FormControl<HotelTypeEnum[] | null>(null), //HotelTypeEnum[] | null;
    hotelClasses: new FormControl<string[] | null>(null),
    hotelServices: new FormControl<HotelServiceEnum[] | null>(null),
    /*  */
    freeSale: new FormControl<boolean | null>(null),
    groupByHotel: new FormControl<boolean | null>(null),
    searchCurrency: new FormControl<string | null>(null)
  });
  private storeCountryCode: string | null = null;

  get childrenAges() {
    return this.formGroup.controls.childrenAges.controls;
  }

  public get hasChildrenAgesError() {
    if (!this.formGroup.controls.childrenAges.controls.length) return false;
    return this.formGroup.controls.childrenAges.controls.some((e) => e.errors);
  }
  public childrenAgesData = Array.from(Array(environment.childMaxAge).keys()).map((i) => ({
    value: i + 1,
    label: `${i + 1}`
  }));

  public get hasLimitErrorOndepartureCheckInAndCheckOutDaysCount() {
    return Boolean(this.formGroup.controls.checkInAndCheckOutDaysCount.errors?.['max']);
  }

  /* Options */
  public countriesTypedToken!: Country & { icon: SVGIcon };
  public countries$ = this.storeService.select(HotelsState.destinationCountriesWithFlagIcon);
  public countriesExtraOptions: (Country & { icon: SVGIcon })[] = [
    { countryName: 'Other Countries', countryCode: null, icon: 'map-marker' }
  ];

  public citiesTypedToken!: City;
  public citiesExtraOptions: City[] = [{ cityUID: null as UnsafeValue<string>, cityName: 'All departure cities' }];

  public locationsTypedToken!: DistrictsTreeItem;
  public locations$ = this.storeService.select(HotelsState.locations);

  public availableHotelsTypedToken!: Hotel;
  public availableHotels$ = this.storeService.select(HotelsState.hotels);

  /*  */
  public mealPlans = STATIC_OPTIONS_HOTEL_MEAL_PLANS;
  public hotelClasses = STATIC_OPTIONS_HOTEL_CLASSES_STARS;
  public hotelServices = STATIC_OPTONS_HOTEL_SERVICES;
  public hotelTypes = STATIC_OPTONS_HOTEL_TYPES;

  public searchByUrlModule = false;

  hotelsOptionFilterPredicate: Predicate<Hotel> | null = null;

  public isInit = false;
  public isReady = false;

  constructor(
    private storeService: StoreService,
    private dynamicModalService: DynamicModalService
  ) {}

  public init() {
    if (this.isInit) return;
    this.initSearchData();
    this.isInit = true;
  }

  private observeMandatoryOptions() {
    const mandatoryOptions: Observable<RequestStateModel>[] = [this.countries$, this.locations$, this.availableHotels$];
    return combineLatest(
      mandatoryOptions.map((e) =>
        e.pipe(
          filter((e) => e.loadState.status === 'completed'),
          map((e) => e.loadState.status === 'completed'),
          take(1)
        )
      )
    ).pipe(map((result) => result.every((value) => value)));
  }
  public updateHotelFilters(isInitialRequest = false) {
    const { hotelClasses, hotelTypes, locations } = this.formGroup.value;

    const cityIds = locations?.filter((el) => el.type === 'city').map((el) => el.value);
    const districtIds = locations?.filter((el) => el.type === 'district').map((el) => el.value);
    if (!isInitialRequest) this.formGroup.controls.hotelCodes.setValue(null);
    if (!hotelClasses && !hotelTypes && !locations && !cityIds && !districtIds) {
      if (this.hotelsOptionFilterPredicate) this.hotelsOptionFilterPredicate = null;
      return;
    }

    this.hotelsOptionFilterPredicate = (hotel: Hotel) => {
      return (
        (!hotelClasses?.length || hotelClasses.includes(hotel.hotelClass as string)) &&
        (!hotelTypes?.length || hotelTypes.includes(hotel.hotelType as HotelTypeEnum)) &&
        (!cityIds?.length || cityIds.includes(hotel.cityUID)) &&
        (!districtIds?.length || districtIds.includes(hotel.districtUID))
      );
    };
  }

  public onAddChildren(children: number) {
    const current = this.formGroup.controls.childrenAges.length;
    if (current < children)
      for (let index = 0; index < children - current; index++)
        this.formGroup.controls.childrenAges.push(new FormControl<number | null>(null, Validators.required));
    else if (current > children)
      for (let index = children; index < current; index++) this.formGroup.controls.childrenAges.removeAt(index);
  }
  public onSubmit() {
    if (!this.isSubmited) this.isSubmited = true;
    if (this.formGroup.invalid) return;
    this.pageRowCount.set(25);

    this.storeService.dispatch(new hotelsSearchHotels());
  }
  private initSearchData() {
    const storedFormData = this.storeService.selectSnapshot(HotelsState.searchFormData);
    if (storedFormData) this.formGroup.patchValue({ ...(storedFormData as typeof this.formGroup.value) });

    const formData = this.formGroup.getRawValue();
    if (storedFormData?.childrenAges?.length)
      for (const iterator of storedFormData.childrenAges)
        this.formGroup.controls.childrenAges.push(new FormControl<number | null>(iterator, Validators.required));

    this.storeService.dispatch([
      new hotelsGetDestinationCountries(),

      new hotelsGetLocations({
        countryCode: formData.countryCode ?? undefined
      }),
      new hotelsGetHotels({
        countryCode: formData.countryCode ?? undefined
      })
    ]);
    this.searchByUrlModule = this.storeService.selectSnapshot(AppState.searchByUrlModule) === 'hotels';

    this.observeMandatoryOptions().subscribe((isReady) => {
      this.isReady = isReady;
      if (!isReady) return;

      // //update hotel filters
      this.calcCheckInAndCheckOutDaysCount();
      this.updateHotelFilters(true);

      //Submit while SearchByUrl module exists
      if (this.searchByUrlModule) {
        if (this.formGroup.controls.searchCurrency.value) {
          const currencyExists = this.storeService
            .selectSnapshot(AppState.currencies)
            .some((e) => e.code === this.formGroup.controls.searchCurrency.value);
          if (currencyExists)
            this.storeService.dispatch(new appSetCurrency(this.formGroup.controls.searchCurrency.value));
        }
        setTimeout(() => {
          this.searchByUrlModule = false;
          this.storeService.dispatch(new appSetSearchByUrlModule(null));
          this.onSubmit();
        }, 100);
      }
    });
  }

  public resetForm() {
    this.formGroup.reset({
      adults: 1,
      children: 0,
      childrenAges: [],
      countryCode: this.storeService.selectSnapshot(AppPersistedState.activedDestinationCountry),
      checkInDate: this.today,
      checkOutDate: addDays(this.today, 7),
      checkInAndCheckOutDaysCount: 7
    });
    this.storeService.dispatch(new UpdateFormValue({ path: this.ngxsForm, value: this.formGroup.getRawValue() }));
    setTimeout(() => {
      this.storeService.dispatch(new hotelsResetResult());
    }, 100);
    this.isSubmited = false;
  }
  public onCountryChange(country: Country) {
    if (!country?.sellCurrency) return;

    this.storeService.dispatch(new appSetSellCurrency('hotels', country.sellCurrency));
    if (!this.isReady) return;

    let countryCode = country.countryCode;
    //reset selected cities & hotels

    if (!countryCode) {
      window.open('https://online.kazunion.com/search_hotel', '_blank');
      countryCode =
        this.storeCountryCode ?? this.storeService.selectSnapshot(AppPersistedState.activedDestinationCountry);
      this.formGroup.controls.countryCode.setValue(countryCode);
      return;
    }
    this.formGroup.controls.locations.setValue(null);
    this.formGroup.controls.hotelCodes.setValue(null);
    this.hotelsOptionFilterPredicate = null;

    this.storeService.dispatch([
      new hotelsGetLocations(
        {
          countryCode
        },
        true
      ),
      new hotelsGetHotels(
        {
          countryCode
        },
        true
      )
    ]);
    this.clearSearchFormFilterControls();
  }
  private clearSearchFormFilterControls() {
    this.formGroup.patchValue({
      mealPlans: null,
      hotelClasses: null,
      hotelTypes: null,
      hotelServices: null
    });
  }

  public calcCheckInAndCheckOutDaysCount(isCheckInDateChange = false) {
    const checkInDate = this.formGroup.controls.checkInDate.value;
    let checkOutDate = this.formGroup.controls.checkOutDate.value;
    const checkInAndCheckOutDaysCount = this.formGroup.controls.checkInAndCheckOutDaysCount.value;

    if (
      checkInDate &&
      isCheckInDateChange &&
      checkInAndCheckOutDaysCount !== null &&
      checkInAndCheckOutDaysCount > -1
    ) {
      checkOutDate = addDays(checkInDate, checkInAndCheckOutDaysCount);
      this.formGroup.controls.checkOutDate.setValue(checkOutDate);
    } else {
      const difference = checkInDate && checkOutDate ? differenceInDays(checkOutDate, checkInDate) : null;
      if (difference && difference < 0 && checkInDate) {
        checkOutDate = addDays(checkInDate, 7);
        this.formGroup.controls.checkOutDate.setValue(checkOutDate);
      }
      this.formGroup.controls.checkInAndCheckOutDaysCount.setValue(
        checkInDate && checkOutDate ? differenceInDays(checkOutDate, checkInDate) : null
      );
    }
  }

  public updateCheckInAndCheckOutDaysCount() {
    const checkInAndCheckOutDaysCount = this.formGroup.controls.checkInAndCheckOutDaysCount.value;
    if (checkInAndCheckOutDaysCount === null || checkInAndCheckOutDaysCount < 0) return;

    let checkInDate = this.formGroup.controls.checkInDate.value;
    if (!checkInDate) {
      checkInDate = new Date();
      this.formGroup.controls.checkInDate.setValue(checkInDate);
    }

    this.formGroup.controls.checkOutDate.setValue(addDays(checkInDate, checkInAndCheckOutDaysCount));
  }

  public openInstructions() {
    this.dynamicModalService.open({
      title: 'Instructions for booking hotels',
      component: HotelsBookingInstructionsComponent,
      width: '620px'
    });
  }

  public ngOnDestroy(): void {
    if (this._destroySubscriptions.next) {
      this._destroySubscriptions.next();
      this._destroySubscriptions.complete();
    }
  }
}
